<template>
    <!-- <nav-box title="自助开牌" :back="true"></nav-box> -->
  <div class="home">
    <div class="home-box flex">
      <div class="home-item flex-column" @click="verification(4)">
        <img src="@/assets/douyin.png" alt="" />
        <span>抖音核销</span>
      </div>
      <div class="home-item flex-column" @click="verification(1)">
        <img src="@/assets/meituan.png" alt="" />
        <span>美团核销</span>
      </div>
      <div class="home-item flex-column" @click="verification(2)">
        <img src="@/assets/koubei.png" alt="" />
        <span>口碑核销</span>
      </div>
      <div class="home-item flex-column" @click="verification(5)">
        <img src="@/assets/jiezhang.png" alt="" />
        <span>自助结账</span>
      </div>
    </div>
  </div>
</template>

<script>
// import navBox from "@/components/nav-box.vue";
export default {
  name: "home",
  data() {
    return {};
  },
  // components: {
  //   navBox,
  // },
  methods: {
    verification(thirdType) {
      // 套餐消费第三方类型(1: 美团, 2: 口碑, 4: 抖音，8: 会员优惠券)
      if(thirdType == 5){
        this.$router.push('/list')
        return
      }
      this.$router.push({
        path: "/verification",
        query: {
          thirdType: thirdType,
        },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 444px 197px 0 197px;
   box-sizing: border-box;
  .home-box {
    box-sizing: border-box;
    flex-wrap: wrap;
    overflow: hidden;
    .home-item {
      margin-right: 146px;
      margin-bottom: 100px;
      align-items: center;
      &:nth-child(2n) {
        margin-right: 0;
      }
      img {
        width: 270px;
        height: 270px;
        margin-bottom: 24px;
      }
      span {
        font-size: 44px;
        font-weight: 700;
        color: #1a1e22;
        line-height: 64px;
      }
    }
  }
}
</style>